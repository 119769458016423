import React from 'react'
import styled from 'styled-components'
import { ReUsePtag } from './ReUsePtag'
import { ReUseHTwotag } from './ReUseHTwoTag'
import TheCFORoleandDigitalInitiativesforGrowthPendingData from '../Data/TheCFORoleandDigitalInitiativesforGrowthPendingData'
import { ReUsePtagwithAnchorTag } from './ReUsePtagwithAnchorTag'
import { ReUsePtagwithFiveAnchorTag } from './ReUsePtagwithFiveAnchorTag'

const AppTheCFORoleandDigitalInitiativesforGrowthRestWapper = styled.div`
  max-width: 1920px;
  margin: auto;
  .RetailIndustryContentSectionEmail {
    padding: 5px;
    @media (max-width: 1090px) {
      padding: 15px;
    }
  }
  .RetailIndustryHeadingEmail {
    max-width: 1150px;
    padding: 0px 15px 15px 45px;
    @media (max-width: 1090px) {
      padding: unset;
      max-width: unset;
    }
  }
  .RetailIndustryLeadParaStyleOne {
    max-width: 1150px;
    padding: 0px 15px 15px 45px;
    @media (max-width: 1090px) {
      padding: unset;
      max-width: unset;
    }
  }
  .RetailIndustryLeadParaStyle {
    max-width: 1150px;
    padding: 15px 15px 15px 45px;

    @media (max-width: 1090px) {
      padding: unset;
      max-width: unset;
    }
  }
  .achorPara {
    color: #34a1d5;
    font-size: 20px;
    font-weight: 700;
    padding-right: 8px;
    padding-left: 8px;
    text-decoration: none;
  }
  .achorPara:hover {
    color: #047cc2;
    text-decoration: underline;
  }
  .iconColor {
    color: #34a1d5;
    padding: 15px 15px 15px 0px;
  }
  .RetailIndustryParaSubPointStyle {
    color: #047cc2;
    max-width: 1150px;
    padding: 15px 15px 15px 45px;
    font-size: 22px;
    font-weight: bold;
    @media (max-width: 1090px) {
      padding: unset;
      max-width: unset;
    }
  }
  .ItalyPara {
    display: flex;
    color: #071741;
    max-width: 1150px;
    font-size: 1.2rem;
    line-height: 1.4;
    padding: 0px 15px 15px 45px;
    @media (max-width: 1090px) {
      padding: unset;
      max-width: unset;
      font-size: 16px;
    }
  }
  .BlogsimageOne {
    width: 90%;
    display: flex;
    padding-left: 24px;
    /* float: right; */
    @media (max-width: 1090px) {
      float: unset;
      width: 100%;
    }
  }
  .RetailLeadParaStyleOne {
    padding: 0px 15px 0px 45px;
  }
  .RetailIndustryHeadingFiveEmail {
    padding: 15px 15px 15px 45px;
  }
  .ParaStyleMultiple {
    padding: 0px 15px 0px 45px;
  }
`

export const AppTheCFORoleandDigitalInitiativesforGrowthRest = () => {
  return (
    <AppTheCFORoleandDigitalInitiativesforGrowthRestWapper>
      <div>
        {TheCFORoleandDigitalInitiativesforGrowthPendingData.map((data, index) => {
          return (
            <div className="MainDigitizationNeedsSection" key={index}>
              <div className="RetailIndustryContentSectionEmail">
                <ReUsePtagwithAnchorTag
                  ParaLeft={data.ParaOneLeft}
                  anchorPara={data.ParaOneAnchor}
                  ParaRight={data.ParaOneRight}
                  href={data.ShiftUrlParaOne}
                  target="_blank"
                  rel="noopener noreferrer"
                  anchorStyle="achorPara"
                  paraStyle="RetailLeadParaStyleOne"
                />
                <ReUseHTwotag Heading={data.HeadingOne} HeadingStyle="RetailIndustryHeadingEmail" />
                <ReUsePtag para={data.ParaTwo} paraStyle="RetailIndustryLeadParaStyleOne" />
                <ReUseHTwotag Heading={data.HeadingTwo} HeadingStyle="RetailIndustryHeadingEmail" />
                <ReUseHTwotag Heading={data.HeadingThree} HeadingStyle="RetailIndustryHeadingEmail" />
                <ReUsePtagwithAnchorTag
                  ParaLeft={data.ParaThreeLeft}
                  anchorPara={data.ParaThreeAnchor}
                  ParaRight={data.ParaThreeRight}
                  href={data.ShiftUrlParaThree}
                  target="_blank"
                  rel="noopener noreferrer"
                  anchorStyle="achorPara"
                  paraStyle="RetailLeadParaStyleOne"
                />
                <img src={data.ImagePathOne} className="BlogsimageOne" />
                <ReUseHTwotag Heading={data.HeadingFour} HeadingStyle="RetailIndustryHeadingFiveEmail" />
                <ReUsePtagwithAnchorTag
                  ParaLeft={data.ParaFourLeft}
                  anchorPara={data.ParaFourAnchor}
                  ParaRight={data.ParaFourRight}
                  href={data.ShiftUrlParaFour}
                  target="_blank"
                  rel="noopener noreferrer"
                  anchorStyle="achorPara"
                  paraStyle="RetailLeadParaStyleOne"
                />
                <ReUseHTwotag Heading={data.HeadingFive} HeadingStyle="RetailIndustryHeadingFiveEmail" />
                <ReUsePtagwithFiveAnchorTag
                  ParaLeft={data.ParaFiveLeft}
                  anchorParaOne={data.ParaFiveAnchorOne}
                  ParaBetweenOne={data.ParaFiveBetweenOne}
                  anchorParaTwo={data.ParaFiveAnchorTwo}
                  ParaBetweenTwo={data.ParaFiveBetweenTwo}
                  anchorParaFour={data.ParaFiveAnchorThree}
                  ParaRight={data.ParaFiveRight}
                  hrefOne={data.AnchorShiftThree}
                  hrefTwo={data.AnchorShiftFour}
                  hrefFour={data.AnchorShiftFive}
                  target="_blank"
                  rel="noopener noreferrer"
                  anchorStyle="achorPara"
                  paraStyle="RetailIndustryLeadParaStyle"
                />
                <ReUseHTwotag Heading={data.HeadingSix} HeadingStyle="RetailIndustryHeadingFiveEmail" />
                <ReUsePtag para={data.ParaSix} paraStyle="RetailIndustryLeadParaStyle" />
                <ReUsePtagwithAnchorTag
                  ParaLeft={data.ParaSevenLeft}
                  anchorPara={data.ParaSevenAnchor}
                  ParaRight={data.ParaSevenRight}
                  href={data.ShiftUrlParaSeven}
                  target="_blank"
                  rel="noopener noreferrer"
                  anchorStyle="achorPara"
                  paraStyle="RetailLeadParaStyleOne"
                />
                <ReUseHTwotag Heading={data.HeadingSeven} HeadingStyle="RetailIndustryHeadingFiveEmail" />
                <ReUsePtag para={data.ParaEight} paraStyle="ParaStyleMultiple" />
              </div>
            </div>
          )
        })}
      </div>
    </AppTheCFORoleandDigitalInitiativesforGrowthRestWapper>
  )
}
