import React from 'react'
import { FaArrowCircleRight } from 'react-icons/fa'

export default [
  {
    id: '1',
    icon: <FaArrowCircleRight />,
    ParaPoint: '⟶',
    ParaOneLeft: 'The current',
    ParaOneAnchor: '9% annual inflation rate',
    ParaOneRight:
      'clearly disrupted prior Annual Operating Plans which were created during much lower inflation rates in 2021. This forces business leaders to carve a path to profitability by investing in the proper tools necessary to gain immediate access to quality information. One thing is now more clear than ever – having an intelligent, data-driven, automated, and holistic spend management solution like Raindrop across the enterprise has become a necessity.',
    ShiftUrlParaOne: 'https://www.bls.gov/news.release/cpi.nr0.htm',
    HeadingOne: 'The CFO and Digital Transformation',
    ParaTwo:
      'CFOs require real value being brought into their company. To help do so, having your CFO aligned with your transformation project can drive stronger performance, address supply chain challenges and global inflation concerns, increase ESG transparency, and prioritize technology spend to accelerate your company forward.  In turn, positive transformation can simultaneously improve the bottom line, increase corporate compliance, and enable growth.',
    HeadingTwo: 'Areas to drive stronger performance using digital tools',
    HeadingThree: '1.  Supply chain issues',
    ParaThreeLeft: 'An article published by',
    ParaThreeAnchor: 'KPMG on the global supply chain issues,',
    ParaThreeRight:
      'states, “CEOs are looking to get on the front foot when it comes to disruption and innovation, with 67% saying they will increase investment in disruption detection and innovation processes.”  The supply chain disruption is here for the foreseeable future, with critical issues unlikely to be resolved soon. This moved supply chain management to the top of corporate concerns, especially in light of increasing complexity.  Acting with urgency, CFOs and Finance leaders have taken on a higher degree of criticality by adopting technology-driven solutions to maximize fully-integrated spend transparency.  Enterprise Spend Management technologies such as Raindrop deliver in a multi-fold manner, including process automation, enhanced financial controls, increased transparency to contractual obligations, and more.  Raindrop’s cloud-based solution provides real-time visibility into the supply chain for proactive decision-making, for CFOs to make real-time decisions, while the business manages the product, people, and processes necessary to improve their bottom line.',
    ShiftUrlParaThree:
      'https://home.kpmg/sg/en/home/insights/2022/03/six-key-trends-impacting-global-supply-chains-in-2022.html#:~:text=Driver%20shortages%2C%20logistics%20provider%20capacity,driving%20discussions%20and%20require%20attention.',
    ImagePathOne:
      'https://storage.googleapis.com/raindroppublic/website_data/CFO%20-%20Obstacles%20and%20Opportunities%202%20(1).jpg',
    HeadingFour: '2.  Inflation and the global economy',
    ParaFourLeft: 'One of the major concerns for CFOs and business leaders is inflation. With the',
    ParaFourAnchor: 'current 9% inflation rate',
    ParaFourRight:
      'creating unwelcomed volatility into the economy, a key CFO task will be re-allocating financial resources based upon the strain in revised budgeting and forecasting.  Investments put in digital solutions are designed to directly address these strains.  So while inflation is unpredictable, having the correct and highly capable tools has become more critical than ever.',
    ShiftUrlParaFour:
      'https://www.cnbc.com/2022/07/13/inflation-rose-9point1percent-in-june-even-more-than-expected-as-price-pressures-intensify.html',
    HeadingFive: '3.  Improving ESG transparency',
    ParaFiveLeft: 'The',
    ParaFiveAnchorOne: 'ISSB',
    ParaFiveBetweenOne: 'recently approved new Sustainability Disclosure Standards',
    ParaFiveAnchorTwo: '(IFRS SDS)',
    ParaFiveBetweenTwo:
      'urging more transparency to ESG standards as they gear up to publish climate reporting requirements later this year. Leading CFOs emphasize effective sustainability disclosures drive informed decision-making while providing relevant information to stakeholders, and external and internal teams.',
    ParaFiveAnchorThree: 'Raindrop',
    ParaFiveRight:
      'supports DE&I initiatives and improves ESG transparency to help businesses in identifying and removing gaps.',
    AnchorShiftThree:
      'https://r.search.yahoo.com/_ylt=Awr1TTSvs_BiEeUsXdC7HAx.;_ylu=Y29sbwNzZzMEcG9zAzEEdnRpZAMEc2VjA3Ny/RV=2/RE=1659970608/RO=10/RU=https%3a%2f%2fwww.ifrs.org%2fgroups%2finternational-sustainability-standards-board%2f/RK=2/RS=rRaMkig0IjbVOjuGcBRqhcBc.s0-',
    AnchorShiftFour:
      'https://r.search.yahoo.com/_ylt=Awr1ThKmHhFjFA0CeCW7HAx.;_ylu=Y29sbwNzZzMEcG9zAzEEdnRpZAMEc2VjA3Ny/RV=2/RE=1662095142/RO=10/RU=https%3a%2f%2fwww.ifrs.org%2fnews-and-events%2fnews%2f2021%2f11%2fglobal-sustainability-disclosure-standards-for-the-financial-markets%2f/RK=2/RS=fNVCnCbrXKJWRs0flXeNoVcFAjM-',
    AnchorShiftFive: 'https://www.raindrop.com/resources/SupplierDiversityandInclusionAchieveYourGoalsWithRaindrop',
    HeadingSix: '4.  Prioritizing tech spend',
    ParaSix:
      'Competition to access quality talent, especially during the existing remote work culture, has moved companies to invest in the necessary technology to enable seamless workflows and performance output across their enterprises.  Simply put, companies can no longer rely on their existing outdated technologies and processes. Investing in modern digital solutions are key drivers to growth and staying ahead of the competitive curve.',
    ParaSevenLeft: 'According to the',
    ParaSevenAnchor: 'Hackett Group,',
    ParaSevenRight:
      '61% of companies are working on enterprise digital transformation, and 39% are planning to upgrade a major technology platform or introduce a new technology.',
    ShiftUrlParaSeven: 'https://www.thehackettgroup.com/blog/three-themes-shaping-the-2022-procurement-agenda/',
    HeadingSeven: 'To Sum Up',
    ParaEight:
      'Investments in digital solutions such as Raindrop have become a critical component for leading organizations today.  The Raindrop platform provides rapid speed to value attainment, capable of being fully up and running in a matter of weeks, intuitive to use, and designed to return money back to your company’s pocket through increased efficiencies and transparency across the enterprise. This AI-based spend intelligent platform is beneficial for an organization’s long-term success and growth, providing quick insights on your expenses.',
  },
]
