import React from 'react'
import styled from 'styled-components'
import { AppSideNavBlogsName } from './AppSideNavBlogsName'
import { AppTheCFORoleandDigitalInitiativesforGrowthRest } from './AppTheCFORoleandDigitalInitiativesforGrowthRest'

const AppTheCFORoleandDigitalInitiativesforGrowthWithNavWapper = styled.div`
  max-width: 1920px;
  margin: auto;
  .RetailIndustrySectionSection {
    display: flex;
    justify-content: space-between;
    padding: 50px;
    @media (max-width: 1090px) {
      flex-wrap: wrap;
      padding: 15px;
    }
  }
  .RetailIndustrySectionNav {
    order: 0;
    @media (max-width: 1090px) {
      order: 1;
      margin: auto;
    }
  }
  .RetailIndustrySectionSectionContent {
    order: 1;
    @media (max-width: 1090px) {
      order: 0;
    }
  }
`

export const AppTheCFORoleandDigitalInitiativesforGrowthWithNav = () => {
  return (
    <AppTheCFORoleandDigitalInitiativesforGrowthWithNavWapper>
      <div className="RetailIndustrySectionSection">
        <div className="RetailIndustrySectionNav">
          <AppSideNavBlogsName />
        </div>
        <div className="RetailIndustrySectionSectionContent">
          <AppTheCFORoleandDigitalInitiativesforGrowthRest />
        </div>
      </div>
    </AppTheCFORoleandDigitalInitiativesforGrowthWithNavWapper>
  )
}
